// botões
$buttonColor: #9D4EDD;
$buttonHover: #ffffff;
$topButtonHover: #7b36cb;

// tema branco
$titleColor: #000000;
$textColor: #EAEAEA;
$subTitle: #868e96;
$cardSubtitle: #666666;

// text color dark theme
$textColorDark: #EAEAEA;

// light background colors
$lightBackground1: #1A1A2E;
$lightBackground2: #1A1A2E;
$lightBackground3: #1A1A2E;
// dark background colors
$darkBackground: #1A1A2E;

// light theme box shadows
$lightBoxShadowDark: rgba(0, 0, 0, 0.2);
$lightBoxShadow: rgba(0, 0, 0, 0.1);
$lightBoxShadowDarker: rgba(0, 0, 0, 0.3);

// dark theme box shadows
$darkBoxShadow: #d9dbdf;
$darkBoxShadow2: #ffffff;

// linear gradients
$experienceCardBlurredDivLG: linear-gradient(
  rgba(0, 0, 0, 0.4),
  rgba(0, 0, 0, 0.2)
);
$experienceCardBannerLG: linear-gradient(
  rgba(0, 0, 0, 0.7),
  rgba(0, 0, 0, 0.5)
);

// borders
$progressBarBackground: rgb(243, 239, 239);
$lightBorder1: rgba(211, 211, 211, 0.397);
$educationCardBorder: #a9a7f9;

// hovers
$achievementCertificateCardHoverDark: rgba(255, 255, 255, 0.2);
$headerHoverBG: #ffffff;
$contactDetailHoverTS: #b5b5b5;


$iconBackground: #333;
$appHeaderBG: #282c34;

// misc colors
$skillsColor: #645beb;
$appLink: #09d3ac;

// social media icons
$faceBook: #3b5998;
$linkedin: #0e76a8;
$github: #333;
$twitter: #1da1f2;
$stackoverflow: #f48024;
$instagram: #c13584;
